<!--设置管理模块--通知设置管理页--详情显示窗口-->
<template>    
  <a-form-model ref="ruleForm">
    <div class="form_row">            
      <div class="form_col">
        <a-form-model-item :label="$t('editModal.a1')" prop="date1">
          <a-range-picker v-model="infoForm.ranges"/>
        </a-form-model-item>
      </div>
      <div class="form_col">
        <a-form-model-item >
          <a-checkbox v-model="infoForm.important">{{$t('editModal.a2')}}</a-checkbox>
        </a-form-model-item>
      </div>
    </div>     
    <div class="form_row">
      <div class="form_col">    
        <a-form-model-item :label="infoForm.desc" prop="date1">
          <a-textarea v-model="infoForm.msg" placeholder="Basic usage" :auto-size="{ minRows: 6, maxRows:6  }" />
        </a-form-model-item> 
        </div>
    </div>                   
  </a-form-model>    
</template>

<script>

export default { 
  props: {
    infoForm: {
      type: Object,
    },
  },  
  
};
</script>

<style scoped>
</style>